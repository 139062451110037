import React from "react"

const Button = ({
  fill = false, 
  isDark = false, 
  text = "START A PROJECT",
  small = false,
  type
}) => {

  if(isDark){
    return (
      <button 
        type={type}
        aria-label={text}
        className="relative btn p-2 mt-4 text-white text-sm font-bold px-6 py-3 rounded uppercase ">
        {text}
        <div data-text={text} className="btn__inner btn__inner--dark" />

      </button>
    )
  }

  return (
    <button 
      type={type}
      aria-label={text}
      className="relative btn p-2 mt-4 text-black text-sm font-bold px-6 py-3 rounded uppercase shadow-xl">
      {text}
      <div data-text={text} className="btn__inner btn__inner--light" />

    </button>
    // <button 
    //   type={type}
    //   aria-label={text}
    //   className="mt-4 bg-primary text-white text-sm font-bold px-6 py-3 rounded uppercase shadow-2xl hover:bg-secondary transition ease-in-out duration-200">
    //   {text}
    // </button>
  )
  
}

export default Button;