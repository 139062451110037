import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import classNames from "classnames"
import Button from "../components/button"
import {
  FiChevronDown
} from "react-icons/fi"

const Navigation = ({ invert, hoverClass = "text-primary"}) => {
  const data = useStaticQuery(graphql`
    {
      prismicHeader {
        type
        data {
          logo {
            url
          }
          nav {
            include_sub_nav
            link_label {
              text
            }
            links {
              url
              isBroken
            }
          }
          sub_nav {
            sub_nav_link {
              url
              isBroken
            }
            sub_nav_name {
              text
            }
          }
        }
      }
    }
  `)

  const { nav, sub_nav, logo } = data.prismicHeader.data
  
  return (  
    <>
    <nav className={classNames({
        "hidden lg:block lg:w-full bg-black": true,
        "bg-white": invert
    })}>
      <div className="container">
        
        <section className={classNames({
          "flex w-full border-gray-800 border-b-hairline": true,
          "border-none": invert
        })}>
          <div className="py-4 flex-1 flex-grow">
            <Link to="/">
              <img style={{filter: invert ? "invert(1)" : "invert(0)"}} className="h-8" src={logo.url} alt="DUDOS Logo"/>
            </Link>
          </div>
          <div 
            style={{left: "0vw"}} 
            className="flex-none bg-white z-50 pb-8 lg:pb-0 bg-gray-100 lg:bg-transparent absolute lg:static w-full h-screen lg:h-auto lg:w-auto">
            <section className={classNames({
                "lg:flex text-base tracking-wide uppercase lg:text-white": true,
                "lg:text-dark": invert
              })}>
              <Link 
                className={`block font-bold pb-4 mx-4 mt-6 text-sm hover:${hoverClass} transition ease-in-out duration-300`} 
                activeClassName={classNames({
                  "border-b-2 border-white": true,
                  "border-primary": invert
                })} to="/">
                Home
              </Link>
              {nav.map((item, key) => {

                if(item.include_sub_nav){
                  return (
                    <div className="relative sub-nav">
                      <Link 
                        key={key}
                        className={`block font-bold pb-4 mx-4 mt-6 text-xs xl:text-sm transition ease-in-out duration-300`}
                        activeClassName={classNames({
                          "border-b-2 border-white": true,
                          "border-primary": invert
                        })} to={item.links.url}>
                        {item.link_label.text}
                        <FiChevronDown className="inline-block ml-2" />
                      </Link>
                      <div className="absolute transparent sub-nav__menu">
                        <div className="bg-gray-900 p-2 rounded w-64">
                          {sub_nav.map((sublink, subkey) => {
                            return (
                              <div>
                                <Link 
                                  key={`subkey-${subkey}`}
                                  className="text-gray-400 font-bold xl:text-sm hover:text-white transition ease-in-out duration-300"
                                  to={sublink.sub_nav_link.url}>
                                  {sublink.sub_nav_name.text}
                                </Link>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                }
                
                return (
                  <Link 
                    key={key}
                    className={`block font-bold pb-4 mx-4 mt-6 text-xs xl:text-sm hover:${hoverClass} transition ease-in-out duration-300`}
                    activeClassName={classNames({
                      "border-b-2 border-white": true,
                      "border-primary": invert
                    })} to={item.links.url}>
                    {item.link_label.text}
                  </Link>
                )
              })}

              {invert ?
                <Link className="-mt-1" to="/contact">
                  <Button text="Contact" />
                </Link>

                :

                <Link className="-mt-1" to="/contact">
                  <Button isDark text="Contact" />
                </Link>
              }

            </section>
          </div>
        </section>
      </div>
    </nav>
    
    </>
  )
}
  
    
export default Navigation

