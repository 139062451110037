
import React from "react"
import logo from "../images/logo.svg"
import { Link } from "gatsby"

import {
  MdPhone,
  MdMailOutline,
  MdLocationOn,
  MdCopyright
} from "react-icons/md"
// import {
//   FaTwitter,
//   FaYoutube,
//   FaPinterest,
//   FaLinkedin
// } from "react-icons/fa"

const Footer = ({isDark, isDesktopOrLaptop}) => {

  if(isDesktopOrLaptop) {
    return (
      <footer className="bg-dark pt-24 pb-16">
        <div className="flex container text-gray-400 text-sm">
          <div className="flex-1 text-gray-400 mr-24">
            <div className="mb-4">
              <Link to="/">
                <img className="h-6" src={logo} alt="DUDOS Logo"/>
              </Link>
            </div>
            <ul className="list-none px-0">
              <li className="py-2">
                <MdPhone color="white" size="1.2rem" className="inline-block mr-4"/>
                <a href="tel:+442039209079">+44 (0)20 3920 9079</a>
              </li>
              {/* <li className="py-2">
                <MdMailOutline color="white" size="1.2rem" className="inline-block mr-4"/>
                <span>mark@dudos.co.uk</span>
              </li> */}
              <li className="py-2">
                <MdLocationOn color="white" size="1.2rem" className="inline-block mr-4"/>
                <span>Bolton, Greater Manchester, UK</span>
              </li>
              <li className="py-2 flex">
                <MdCopyright color="white" size="2.2rem" className="block mr-4"/>
                <p className="pr-16 mt-2">2020. All rights reserved, DUDOS Online Ltd. UK registered company Company number: 08159006 VAT Registered: GB167444880 </p>
              </li>
            </ul>
          </div>

          <div className="flex-1">
            <strong className="text-white font-bold text-base">Website</strong>
            <ul className="list-none px-0 mt-2">
              <li className="py-1">
                <Link className="hover:text-primary" to="/">
                  Home
                </Link>
              </li>
              <li className="py-1">
                <Link className="hover:text-primary" to="/about">
                  About
                </Link>
              </li>
              <li className="py-1">
                <Link className="hover:text-primary" to="/services">
                  Services
                </Link>
              </li>
              <li className="py-1">
                <Link className="hover:text-primary" to="/portfolio">
                  Portfolio
                </Link>
              </li>
              <li className="py-1">
                <Link className="hover:text-primary" to="/blog">
                  Insights
                </Link>
              </li>
              <li className="py-1">
                <Link className="hover:text-primary" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            {/* <strong className="text-white font-bold text-base">Helpful links</strong>
              <ul className="list-none px-0 mt-2">
                <li className="py-1">
                  <Link className="hover:text-primary" to="/terms">
                    Terms &amp; conditions
                  </Link>
                </li>
                <li className="py-1">
                  <Link className="hover:text-primary" to="/privacy">
                    Privacy policy
                  </Link>
                </li>
              </ul> */}
          </div>

          <div className="flex-1">
            <strong className="block mb-4 text-white font-bold text-base">Join us</strong>
            <form name="subscribe" method="post" data-netlify="true" className="mt-0 mb-8">
              <input type="hidden" name="bot-field" data-netlify-honeypot="bot-field" />
              <input type="hidden" name="form-name" value="subscribe" />
              <label>
                <span className="text-white mb-2 block text-xs text-gray-400">Enter your email here:</span>
                <div className="flex">
                  <input name="subscriber" id="subscriber" className="text-white text-sm block bg-gray-700 py-2 px-2 w-1/2" type="email" />
                  <button className="bg-secondary text-white text-xs px-4 rounded-tr-md font-bold">SUBSCRIBE</button>
                </div>
              </label>
              </form>
              {/* <div className="flex mb-12">
                <FaLinkedin color="white" size="1.2rem" className="mr-6 opacity-50" />
                <FaTwitter color="white" size="1.2rem" className="mr-6 opacity-50" />
                <FaYoutube color="white" size="1.2rem" className="mr-6 opacity-50" />
                <FaPinterest color="white" size="1.2rem" className="opacity-50" />
              </div> */}
              <strong className="text-white font-bold text-base">Helpful links</strong>
              <ul className="list-none px-0 mt-2">
                <li className="py-1">
                  <Link className="hover:text-primary" to="/terms">
                    Terms &amp; conditions
                  </Link>
                </li>
                <li className="py-1">
                  <Link className="hover:text-primary" to="/privacy">
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>

        </div>
      </footer>
    )    
  }  


  return (
    <footer className="bg-dark pt-24 pb-16">
      <div className="md:flex container text-gray-400 text-sm">
        <div className="flex-1 text-gray-400">
          <div className="mb-4">
            <Link to="/">
              <img className="h-6" src={logo} alt="DUDOS Logo"/>
            </Link>
          </div>
          <ul className="list-none px-0 mb-8">
            <li className="py-2">
              <MdPhone color="white" size="1.2rem" className="inline-block mr-4"/>
              <a href="tel:+442039209079">+44 (0)20 3920 9079</a>
            </li>
            {/* <li className="py-2">
              <MdMailOutline color="white" size="1.2rem" className="inline-block mr-4"/>
              <span>info@dudos.co.uk</span>
            </li> */}
            <li className="py-2">
              <MdLocationOn color="white" size="1.2rem" className="inline-block mr-4"/>
              <span>St Dominics Mews, Greater Manchester, UK</span>
            </li>
            <li className="py-2 flex">
              <div>
              <MdCopyright color="white" size="1.2rem" className="mr-4"/>
              </div>
              <p className="pr-16 ">2020. All rights reserved, DUDOS Online Ltd. UK registered company Company number: 08159006 VAT Registered: GB167444880 </p>
            </li>
          </ul>
          <strong className="block mb-4 text-white font-bold text-base">Join us</strong>
          <form name="subscribe" method="post" data-netlify="true" className="mt-0 mb-8">
            <input type="hidden" name="bot-field" data-netlify-honeypot="bot-field" />
            <input type="hidden" name="form-name" value="subscribe" />
            <label>
              <span className="text-white mb-2 block text-xs text-gray-400">Enter your email here:</span>
              <div className="flex">
                <input name="subscriber" id="subscriber" className="text-white text-sm block bg-gray-800 py-1 px-2 w-1/2" type="email" />
                <button className="bg-secondary text-white text-xs px-4 rounded-tr-md">SUBSCRIBE</button>
              </div>
            </label>
            </form>
            {/* <div className="flex mb-12">
              <FaLinkedin color="white" size="1.2rem" className="mr-6 opacity-50" />
              <FaTwitter color="white" size="1.2rem" className="mr-6 opacity-50" />
              <FaYoutube color="white" size="1.2rem" className="mr-6 opacity-50" />
              <FaPinterest color="white" size="1.2rem" className="opacity-50" />
            </div> */}
        </div>

        <div className="md:w-1/4">
          <strong className="text-white font-bold text-base">Website</strong>
          <ul className="list-none px-0 mt-2">
            <li className="py-1">
              <Link className="hover:text-primary" to="/">
                Home
              </Link>
            </li>
            <li className="py-1">
              <Link className="hover:text-primary" to="/about">
                About
              </Link>
            </li>
            <li className="py-1">
              <Link className="hover:text-primary" to="/services">
                Services
              </Link>
            </li>
            <li className="py-1">
              <Link className="hover:text-primary" to="/portfolio">
                Portfolio
              </Link>
            </li>
            <li className="py-1">
              <Link className="hover:text-primary" to="/blog">
                Blog
              </Link>
            </li>
            <li className="py-1">
              <Link className="hover:text-primary" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <strong className="text-white font-bold text-base">Helpful links</strong>
            <ul className="list-none px-0 mt-2">
              <li className="py-1">
                <Link className="hover:text-primary" to="/terms">
                  Terms &amp; conditions
                </Link>
              </li>
              <li className="py-1">
                <Link className="hover:text-primary" to="/privacy">
                  Privacy policy
                </Link>
              </li>
            </ul>

        </div>

        
      </div>
    </footer>
  )    



}

export default Footer;