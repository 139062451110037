import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import classNames from "classnames"
import {
  MdMenu,
  MdClose
} from "react-icons/md"
import {
  FiChevronDown
} from "react-icons/fi"

// import {
//   FaTwitter,
//   FaYoutube,
//   FaPinterest,
//   FaLinkedin
// } from "react-icons/fa"

const MobileNavigation = () => {
  const [toggle, setToggle] = useState(false);
  const [subnavDropdown, setSubnavDropdown] = useState(false);
  const data = useStaticQuery(graphql`
    {
      prismicHeader {
        type
        data {
          logo {
            url
          }
          nav {
            include_sub_nav
            link_label {
              text
            }
            links {
              url
              isBroken
            }
          }
          sub_nav {
            sub_nav_link {
              url
              isBroken
            }
            sub_nav_name {
              text
            }
          }
        }
      }
    }
  `)

  const { nav, sub_nav, logo } = data.prismicHeader.data
  
  return (  

    <nav className="relative">
      <div className="px-8">
        <section className="flex w-full border-dark border-b-0 border-t-0">
          <div className="py-4 flex-1 flex-grow">
            <Link to="/">
              <img style={{filter: "invert(1)"}} className="h-8" src={logo.url} alt="DUDOS Logo"/>
            </Link>
          </div>
          
          <button aria-label="open and close navigation menu" onClick={() => setToggle(!toggle)} className="px-4 -mt-2 pt-2 -mr-4 bg-gray-200 focus:outline-none focus:bg-gray-200">
            {toggle ? <MdClose color="black" size="24px" /> : <MdMenu color="black" size="24px" />}
          </button>
        </section>

        
        <section>
          <div 
            style={{left: toggle ? "0vw" : "-100vw", transition: "left ease-in-out 200ms"}} 
            className="bg-white border overflow-scroll z-40 pb-8 bg-gray-100 absolute w-full pre-set-nav">
            
            <section className="text-base tracking-wide text-dark uppercase">
              <Link 
                className="block mx-6 mt-6 pb-4 border-b-hairline border-gray-300 hover:text-primary transition ease-in-out duration-300" 
                activeClassName="border-primary" to="/">
                Home
              </Link>
              {nav.map((item, key) => {

                return (
                  <>
                  <div className="flex">
                    <Link 
                      key={key}
                      className="flex-1 block mx-6 mt-6 pb-4 border-b-hairline border-gray-300 hover:text-primary transition ease-in-out duration-300" 
                      activeClassName="border-primary" to={item.links.url}>
                      <span>{item.link_label.text}</span>
                    </Link>
                    {item.include_sub_nav &&
                      <button aria-label="Open sub nav" onClick={() => setSubnavDropdown(!subnavDropdown)} className="mt-4 px-4 bg-gray-200 focus:outline-none">
                        <FiChevronDown />
                      </button>
                    }
                
                  </div>
                  {item.include_sub_nav &&
                    <div className={classNames({
                      "py-0 px-2 bg-gray-200 h-0 overflow-hidden transition-all ease-in-out duration-300": true,
                      "h-auto py-2 transition-all ease-in-out duration-300": subnavDropdown
                    })}>
                      {sub_nav.map((sublink, subkey) => {
                          return (
                            <div>
                              <Link 
                                key={`subkey-${subkey}`}
                                className="text-black text-xs ml-8 transition ease-in-out duration-300"
                                to={sublink.sub_nav_link.url}>
                                {sublink.sub_nav_name.text}
                              </Link>
                            </div>
                          )
                        })}
                    </div>
                  }
                  </>
                )
              })}
              
            </section>

            <section className="bg-gray-200 py-2">
              <Link 
                className="block mx-6 mt-4 text-sm pb-4" 
                activeClassName="border-dark" to="/terms/">
                Terms &amp; conditions
              </Link>
              <Link 
                className="block mx-6 mt-4 text-sm pb-4 " 
                activeClassName="border-dark" to="/privacy/">
                Privicy policy
              </Link>
            </section>

            {/* <section>
              <Link 
                className="block mx-6 mt-6 text-xs pb-4 border-b-hairline border-gray-300 hover:text-primary transition ease-in-out duration-300" 
                activeClassName="border-primary" to="/contact">
                Contact us
              </Link>
            </section> */}

            {/* <section className="pl-6 py-6">
              <div>
                <strong>Follow us:</strong>
              </div>
              <aside className="flex">
              <div className="pr-4 pt-2 cursor-pointer">
                <FaLinkedin color="dimgray" size="1.2rem" />
              </div>
              <div className="px-4 pt-2 cursor-pointer ">
                <FaTwitter color="dimgray" size="1.2rem" />
              </div>
              <div className="px-4 pt-2 cursor-pointer ">
                <FaPinterest color="dimgray" size="1.2rem" />
              </div>
              <div className="px-4 pt-2 cursor-pointer">
                <FaYoutube color="dimgray" size="1.2rem" />
              </div>
              </aside>
            </section> */}



          </div>
        </section>


      </div>
    </nav>
  )
}
  
    
export default MobileNavigation
