/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import Navigation from "../organisms/Navigation"
import MobileNavigation from "../organisms/MobileNavigation"
import Footer from "../organisms/Footer"
import { Link } from "gatsby"
import SEO from "../templates/SEO"
import MediaQuery, { useMediaQuery } from 'react-responsive'
import {
  IoMdContact,
  IoMdCall
} from "react-icons/io"

import { useScrollPosition } from "../utils/useScrollPosition"

import "../styles.css"

const Layout = ({ children, page }) => {
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  })
  
  const [headerStyle, setHeaderStyle] = useState({
    transition: 'all 200ms ease-in'
  })

  useScrollPosition(
    ({ currPos = {y: -29} }) => {
      const isVisible = currPos.y < -30

      const shouldBeStyle = {
        visibility: isVisible ? 'visible' : 'hidden',
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
        transform: isVisible ? 'none' : 'translate(0, -100%)',
        position: "fixed",
        top: "0",
        zIndex: "100"
      }
  
      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return
  
      setHeaderStyle(shouldBeStyle)
    },
    [headerStyle]
  )

  return (
      <section className="relative mt-0 overflow-hidden">
        <SEO page={page} />

        <MediaQuery minDeviceWidth={1024}>
          <>
          <Navigation />
            <div 
              style={{ ...headerStyle }}
              className="bg-white w-full shadow fixed -top-full z-50">
                <Navigation invert floatHoverClass="text-secondary" />
            </div>
          </>
        </MediaQuery>

        <MediaQuery maxDeviceWidth={1023}>

          <div className="bg-white w-screen shadow-md fixed top-0 z-50">
            <MobileNavigation />
          </div> 
        </MediaQuery>

        <main>
          {children}
        </main>
        
        <Footer isDesktopOrLaptop={isDesktopOrLaptop} />

        <MediaQuery maxDeviceWidth={1023}>
          <div style={{bottom: "0", background: "rgba(255, 255, 255, 1)"}} className="grid grid-cols-2 w-full px-2 fixed z-50 border-t-hairline">
            <Link to="/contact/" className="text-sm w-full text-center text-black font-bold uppercase p-2 my-2 rounded">
              <span><IoMdContact size="24px" className="mr-2 inline-block align-bottom leading-tight text-gray-700" />Get in touch</span>
            </Link>
            <a href="tel:+442039209079" className="text-sm w-full text-center text-black font-bold uppercase p-2 my-2 rounded bg-gray-200">
              <span><IoMdCall size="24px" className="mr-2 inline-block align-bottom leading-tight text-gray-700" />Call</span>
            </a>
          </div>
        </MediaQuery>
      </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
